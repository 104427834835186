.container {
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    padding: 0 5rem;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.75);
    cursor: pointer;
}

.close {
    background-size: contain;
    position: fixed;
    opacity: 0.3;
    top: 0.75rem;
    right: 2rem;
    width: 3rem;
    height: 3rem;
    pointer-events: none;
}

.modal {
    position: relative;
    background-color: #FFF;
    border-radius: 1rem;
    width: 100%;
    padding: 2rem;
    max-width: 1024px;
    box-sizing: border-box;
    cursor: default;
    margin: 5rem auto;
}

.modal h2 {
    font-size: 2.28rem;
    line-height: 1em;
    margin: 0 0 3rem 0;
}
.container {
    margin: 0 auto;
}

.container h3 {
    margin: 0 0 0.75rem 0;
}

.container article:not(:last-child) {
    margin-bottom: 2rem;
}
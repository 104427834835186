.button {
    background-color: #F2C94C;
    color: #333333;
    border: none;
    border-radius: 0.3rem;
    padding: 1rem 3rem;
    font: inherit;
    font-weight: bold;
    cursor: pointer;
}

.button:hover {
    background-color: #FFD451;
}
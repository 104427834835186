.container {
    display: grid;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0;
    grid-template-columns: repeat(auto-fill, 220px);
    column-gap: 1rem;
    row-gap: 2.5rem;
}

.container h2 {
    font-size: 2.28rem;
    line-height: 1.5em;
    margin: 0;
}

.container img {
    width: 220px;
    height: 220px;
    margin-bottom: 0.75rem;
    border-radius: 0.5rem;
}

.container article {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-weight: bold;
}
html, body {
    margin: 0;
    font-size: 16px;
    line-height: 1.5em;
    color: #333333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    scroll-behavior: smooth;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

a {
    text-decoration: none;
    color: #1872E8;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

.js-focus-visible .focus-visible {
    outline: 2px solid red;
}
.header {
    height: 4rem;
    border-bottom: 1px solid rgba(52,55,61,.1);
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: inherit;
}

.logoImage {
    margin-right: 0.75rem;
    width: 2.5rem;
}

.stats::before {
    display: inline-block;
    content: '';
    border-radius: 50%;
    background-color: #6FCF97;
    height: 0.8rem;
    width: 0.8rem;
    margin-right: 0.5rem;
    vertical-align: baseline;
}

.statsHide {
    visibility: hidden;
}

.nav {
    display: flex;
}

.link {
    position: relative;
    text-decoration: none;
    color: inherit;
    margin: 0 1rem;
}

.link::before {
    content: '';
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -0.5rem;
    background: #F2C94C;
    height: 0.125rem;
    transition-property: left, right;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.link:hover::before {
    left: 0;
    right: 0;
}
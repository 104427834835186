.container {
    display: flex;
    position: relative;
}

.button {
    font: inherit;
    border: none;
    background-color: initial;
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    text-align: inherit;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.buttonArrow {
    padding-right: 2rem;
}

.dropdown {
    position: absolute;
    width: 100%;
    background-color: #FFF;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
    z-index: 9999;
    max-height: 300px;
    overflow: auto;
}

.dropdownUp {
    top: -0.5rem;
    bottom: 0;
    transform: translateY(-100%);
}

.dropdownDown {
    top: 100%;
    margin-top: 0.5rem;
}

.item {
    font: inherit;
    padding: 0.5rem;
    text-align: inherit;
    background-color: initial;
    border: none;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item:first-child {
    border-radius: 0.5rem 0.5rem 0 0;
}

.item:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
}

.item:hover, .item:focus {
    background-color: #FAEBBC;
}

.arrow {
    display: inline-block;
    width: 0; 
    height: 0; 
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 0.5rem;
    z-index: -1;
}

.arrowUp {
    border-bottom: 0.5rem solid #333333;
}

.arrowDown {
    border-top: 0.5rem solid #333333;
}

.searchWrapper {
    padding: 0.4rem;
}

.search {
    border-radius: 0.3rem;
    border: none;
    box-shadow: inset 0 0 0 1px #E2E2E2;
    padding: 0.4rem 0.6rem;
    font-size: 0.9rem;
    line-height: 1.5rem;
    display: block;
    width: 100%;
}

.search:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #F2C94C;
}

.notFound {
    display: block;
    text-align: center;
    margin: 0.5rem auto;
}
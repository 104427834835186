.cover {
    background-position: center;
    background-size: cover;
    width: 640px;
    height: 360px;
    position: relative;
    border: none;
    display: flex;
    padding: 0;
    font: inherit;
}

.buttonWrapper {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.title {
    display: block;
    padding: 0.5rem 1rem;
    color: #FFF;
}
.main {
    flex: 1;
}

.banner {
    position: relative;
    height: 600px;
    overflow: hidden;
}

.banner .bannerPreview {
    position: absolute;
    top: 50%; 
    left: 50%;
    width: 100%;
    min-width: 1066.66px;
    transform: translate(-50%, -50%);
    z-index: -1000;
    min-height: 600px;
    background-color: #333;
    background-image: url("../../assets/poster.jpg");
    background-size: cover;
    background-position: center center;
    box-shadow: 0 0 0 1px #19191E;
}

@supports (background-image: -webkit-image-set(url('../../assets/poster.webp') 1x)) {
    .banner .bannerPreview {
        background-image: -webkit-image-set(url('../../assets/poster.webp') 1x);
    }
}

.banner .bannerLink {
    margin-top: 0.5rem;
    opacity: 0.5;
}

.banner section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.1);
}

.banner section h1 {
    font-size: 4.2rem;
    line-height: 1.5em;
    font-weight: normal;
    margin: 0 0 2rem 0;
}

.banner section h2 {
    font-size: 2.28rem;
    line-height: 1.5em;
    font-weight: normal;
    margin: 0 0 4rem 0;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: 1.2rem;
    line-height: 1.5em;
}

.section h2 {
    font-size: 2.28rem;
    margin: 0 0 2rem 0;
    line-height: 1em;
}

.section ul {
    max-width: 900px;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.section ul li {
    display: flex;
    margin: 1rem 0;
}

.section ul li span {
    line-height: 1.5em;
}

.section i {
    display: inline-block;
    color: inherit;
    font-weight: bold;
    font-style: normal;
    min-width: 1rem;
    height: 1rem;
    padding: 0.5rem;
    background-color: #F2C94C;
    border-radius: 0.5rem;
    text-align: center;
    vertical-align: middle;
    line-height: 1rem;
    margin-right: 1rem;
}

.youtube {
    margin-top: 2rem;
}

.modsAndMaps {
    height: 600px;
    width: 100%;
    color: #FFF;
    display: block;
    position: relative;
}

.modsAndMaps::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-image: url("../../assets/poster1.jpg");
    background-position: center center;
    background-size: cover;
}

@supports (background-image: -webkit-image-set(url('../../assets/poster1.webp') 1x)) {
    .modsAndMaps::before {
        background-image: -webkit-image-set(url('../../assets/poster1.webp') 1x);
    }
}

.modsAndMaps div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.4);
}

.modsAndMaps section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;
    color: #FFF;
}

.modsAndMaps article {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
}

.modsAndMaps article:not(:first-child) {
    padding-left: 2rem;
}

.modsAndMaps h2 {
    font-size: 2.28rem;
    line-height: 1.5em;
    margin: 0;
}

.modsAndMaps span {
    display: block;
    font-size: 1.2rem;
    line-height: 1.5em;
    margin: 2rem 0;
}

.contacts {
    width: 100%;
    max-width: 640px;
    box-sizing: content-box;
}

.contacts span {
    margin-bottom: 1rem;
}

.contacts span p {
    margin: 0.5em 0;
}

.contacts .contactsDiscordLink {
    width: inherit;
}

.contacts .contactsDiscordImage {
    width: inherit;
}
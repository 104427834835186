.footer {
    height: 4rem;
    border-top: 1px solid rgba(52,55,61,.1);
}

.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select {
    position: absolute;
    width: 6.75rem;
    right: 2rem;
    top: 50%;
    justify-content: space-between;
    transform: translateY(-50%);
}